import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { RiArrowLeftLine, RiArrowRightLine } from "react-icons/ri"
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image"
import { useLocation } from "@reach/router"

import DefaultLayout from "@pitayan/gatsby-theme-pitayan/src/layouts/Default"
import PostMeta from "@pitayan/gatsby-theme-pitayan/src/components/PostMeta"
import AuthorCard from "@pitayan/gatsby-theme-pitayan/src/components/AuthorCard"
import SocialSharing from "@pitayan/gatsby-theme-pitayan/src/components/SocialSharing"
import CategoryTags from "@pitayan/gatsby-theme-pitayan/src/components/CategoryTags"
import BackToTop from "@pitayan/gatsby-theme-pitayan/src/components/BackToTop"
import PostAuthors from "@pitayan/gatsby-theme-pitayan/src/components/PostAuthors"
import SelectionPopup from "@pitayan/gatsby-theme-pitayan/src/components/SelectionPopup"
import ScrollVisibility from "@pitayan/gatsby-theme-pitayan/src/components/ScrollVisibility"

import { useSiteMetadata } from "@pitayan/gatsby-theme-pitayan/src/hooks"
import { SOCIAL_RESOURCES } from "@pitayan/gatsby-theme-pitayan/src/constants"
import { Author } from "@pitayan/gatsby-theme-pitayan/src/pages/authors"
import PostsGroup, { PostNode } from "@pitayan/gatsby-theme-pitayan/src/components/PostsGroup"

type PostProps = {
  data: {
    mdx: {
      body: string
      frontmatter: {
        author: Author[]
        title: string
        date: string
        categories: string[]
        hero: {
          medium: ImageDataLike
        }
        description: string
        keywords: string
      }
      fields: {
        slug: string
      }
      timeToRead: number
      relatedPosts: PostNode[]
    }
  },
  pageContext: {
    previous: {
      title: string
      slug: string
    }
    next: {
      title: string
      slug: string
    }
  }
}

const PostImage: React.FC<{ image: any }> = ({ image }: any) => {
  return image ? (
    <GatsbyImage className="gatsby-resp-image-image" image={image} alt="" />
  ) : null
}

const Post: React.FC<PostProps> = ({
  data: {
    mdx: {
      body,
      frontmatter: {
        author: coAuthors,
        title,
        date,
        categories,
        hero,
        description,
        keywords,
      },
      fields: { slug },
      timeToRead,
      relatedPosts,
    },
  },
  pageContext: {
    previous,
    next
  }
}) => {
  const [postTarget, setPostTarget] = useState<HTMLElement | null>()
  const postImage = getImage(hero?.medium)
  const { siteUrl } = useSiteMetadata()
  const { href: url } = useLocation()

  const authors = coAuthors.map(({ id, yamlId, name, bio, sns }) => {
    const socialUrls = sns
      .filter((s: string[]) => s[0] != "mailto" && s[0] != "url")
      .map((s: string[]) => {
        const network = SOCIAL_RESOURCES[s[0]].url
        const profile = s[1]
        return `${network}/${profile}`
      })

    return {
      id,
      yamlId,
      name,
      bio,
      socialUrls,
    }
  })

  return (
    <DefaultLayout
      postDescription={description}
      pageImage={postImage.images.fallback.src}
      pageUrl={`${siteUrl}${slug}`}
      postTitle={title}
      pageTitle={title}
      keywords={keywords}
      date={date}
      timeToRead={timeToRead}
      authors={authors}
    >
      <SelectionPopup target={postTarget} />

      <div className="hidden md:block">
        <ScrollVisibility className="fixed right-[6%] bottom-[6%] flex flex-col justify-center z-50">
          <BackToTop />
        </ScrollVisibility>
      </div>

      <div className="max-w-lg md:max-w-2xl mx-auto mb-24">
        <h1 className="text-center">{title}</h1>
        <PostMeta className="block mb-4 text-center" date={date} timeToRead={timeToRead} />

        
      </div>

      <div className="text-center mb-24">
        <PostImage image={postImage} />
      </div>

      <article className="markdown" ref={ref => setPostTarget(ref)}>
        <MDXProvider components={{}}>
          <MDXRenderer>{body}</MDXRenderer>
        </MDXProvider>
      </article>

      

      <div className="my-24 max-w-lg sm:max-w-full mx-auto">
        <h5 className="mb-12 text-center">Related</h5>
        <PostsGroup
          posts={relatedPosts}
          className="grid sm:grid-cols-3 row-auto auto-cols-auto gap-8"
        />
      </div>
    </DefaultLayout>
  )
}

export const pageQuery = graphql`
  query ($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      timeToRead
      frontmatter {
        author {
          id
          yamlId
          name
          initial
          bio
          sns
          avatar {
            normal: childImageSharp {
              gatsbyImageData(
                width: 128
                placeholder: BLURRED
                formats: [AUTO]
              )
            }
          }
        }
        title
        description
        date(formatString: "MMMM Do, YYYY")
        categories
        hero {
          medium: childImageSharp {
            gatsbyImageData(
              width: 944
              height: 531
              placeholder: BLURRED
              formats: [AUTO]
            )
          }
        }
      }
      fields {
        slug
      }
      relatedPosts {
        id
        timeToRead
        fields {
          slug
        }
        frontmatter {
          title
          date(formatString: "MMMM Do, YYYY")
          categories
          hero {
            normal: childImageSharp {
              gatsbyImageData(
                width: 768
                placeholder: BLURRED
                formats: [AUTO]
              )
            }
          }
          description
        }
      }
    }
  }
`

export default Post
